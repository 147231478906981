<template>
  <div>
    <section class="selfcare-title d-flex">
      <div class="px-2">
        <a @click="goBack()" class="custom-link text-light">
          <i class="text-light bi bi-arrow-left"></i>
        </a>
      </div>
      <div class="text-light width-available text-center">
        <strong>
          Offers & Promotions
        </strong>
      </div>
    </section>

    <div style="background-color: var(--gray-white);min-height: 70vh;" class="promotions">
      <div class="list">
        <!-- <div class="no-games-message">
          Promotions Coming Soon.
        </div> -->
        <div class="" v-for="(promotion, index) in promotions" :key="index">
          <router-link to="/freebet">
            <img :src="promotion.img" alt="Promotion image"
              style="max-width: 100%; height: auto;border-radius: 10px;" />
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Offers',
  data() {
    return {
      promotions: [
        {
          title: "Title",
          description: "Decription !" +
            "",
          img: "/img/carousel/banner1.png"
        },{
          title: "Title",
          description: "Decription !" +
            "",
          img: "/img/carousel/banner2.png"
        },
      ],
    };
  },
  components: {},
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "offers");
    this.reloadProfile();

  }

}
</script>

<style scoped>
.no-games-message {
  text-align: center;
  padding: 20px;
  font-size: 20px;
  color: #283544;
  background-color: #ffffff;
  border: 1px solid #283544;
  border-radius: 5px;
  margin: 20px;
}

.promotions {
  padding: 20px;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  flex-direction: column;
  color: #000000;
}

.card {
  width: 100%;
  flex-grow: 1;
  background-color: var(--lightest-gray1);
  ;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  color: #fff;
}

.card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.card h3 {
  font-size: 18px;
  margin: .5em 0;
}

.card p {
  line-height: 1.6;
}
</style>